import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useLocationAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { createItem, ObjectId } = realmConnection()
  const userData = store.state?.userStore?.userData
  const location = ref({})
  const isSubmitting = ref(false)
  const collection = 'location'

  const create = async (data) => {
    isSubmitting.value = true

    const payload = {
      client_id: ObjectId(userData.client.$oid)
    }

    if (data.location?.value) payload.location = data.location.value
    if (data.zone?.value) payload.zone = data.zone.value
    if (data.agency?.value) payload.agency = data.agency.value

    try {
      await createItem({ collection, payload })
      showSuccessMessage(i18n.t('message.location_added'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.location_add_error'))
    } finally {
      router.push({ name: 'organization-location-list' })
      isSubmitting.value = false
    }
  }

  return {
    create,
    location,
    isSubmitting,
  }
}
